import Swal, { SweetAlertPosition, SweetAlertResult } from 'sweetalert2';

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class SwalService {
	constructor() {}

	showToastError(
		err: string,
		timer: number = 3000,
		position: SweetAlertPosition = 'bottom',
	): Promise<SweetAlertResult<any>> {
		return Swal.fire({
			toast: true,
			position: position,
			showConfirmButton: false,
			icon: 'error',
			timer: timer,
			title: err,
			heightAuto: false,
		});
	}

	showValidators(
		msg: string,
		position: SweetAlertPosition = 'center',
		showConfirmButton: boolean = false,
	) {
		return Swal.fire({
			position: position,
			showConfirmButton: showConfirmButton,
			icon: 'info',
			html: `<div class="text-left py-1 font-bold text-xl sm:text-2xl text-primary w-80 sm:w-96 m-auto">
					${msg}
				  </div>`,
			heightAuto: false,
			confirmButtonColor: 'var(--ui-primary)',
		});
	}

	showInfo(
		msg: string,
		position: SweetAlertPosition = 'center',
		showConfirmButton: boolean = false,
	) {
		return Swal.fire({
			position: position,
			showConfirmButton: showConfirmButton,
			icon: 'info',
			title: msg,
			heightAuto: false,
			confirmButtonColor: 'var(--ui-primary)',
		});
	}

	showError(
		msg: string,
		position: SweetAlertPosition = 'center',
		showConfirmButton: boolean = false,
		showCloseButton: boolean = false,
	) {
		return Swal.fire({
			position: position,
			showConfirmButton: showConfirmButton,
			icon: 'error',
			title: msg,
			heightAuto: false,
			confirmButtonColor: 'var(--ui-primary)',
			showCloseButton: showCloseButton,
		});
	}

	showSuccess(
		msg: string,
		timer: number = 3000,
		position: SweetAlertPosition = 'center',
		showConfirmButton: boolean = false,
	): Promise<SweetAlertResult<any>> {
		return Swal.fire({
			position: position,
			showConfirmButton: showConfirmButton,
			icon: 'success',
			timer: timer,
			title: msg,
			heightAuto: false,
		});
	}

	showSuccessToast(
		msg: string,
		timer: number = 3000,
		position: SweetAlertPosition = 'top',
	): Promise<SweetAlertResult<any>> {
		return Swal.fire({
			toast: true,
			position: position,
			showConfirmButton: false,
			icon: 'success',
			timer: timer,
			title: msg,
			heightAuto: false,
		});
	}

	confirmMessage(title: string) {
		return Swal.fire({
			title: title,
			showCancelButton: true,
			confirmButtonText: 'Si',
			confirmButtonColor: 'var(--ui-warn)',
			heightAuto: false,
		});
	}
	confirmDeleteAccount(title: string, body: string) {
		return Swal.fire({
			title: title,
			html: body,
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			confirmButtonColor: 'var(--ui-warn)',
			heightAuto: false,
			reverseButtons: true,
			input: 'password',
			inputAttributes: {
				autocapitalize: 'off',
			},
			preConfirm: (password) => {
				if (!password) this.showToastError('Debes ingresar la contraseña.');
			},
		});
	}
}
